
import Nav from './components/header/header';
function App() {
  return (
    <div className="App">
      <Nav/>
    </div>
  );
}

export default App;
